import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useGlobalStore } from "@/stores/useGlobalStore";
import {
  createProduct,
  destroyProducts,
  fetchProduct,
  fetchProducts,
} from "@/api/products";
import { Severity } from "@/interfaces/Severity";
import { Product } from "@/interfaces/Product";
import { ProductAllergyMatch } from "@/interfaces/ProductAllergyMatch";
import router from "@/router";
import { Ingredient } from "@/interfaces/Ingredient";

export const useProductsStore = defineStore(
  "products",
  () => {
    const { apiCall } = useGlobalStore();
    // product
    const product: Ref<Product | null> = ref(null);
    const ingredients: Ref<Ingredient[] | null> = ref(null);
    const allergens: Ref<Ingredient[] | null> = ref(null);
    const traces: Ref<Ingredient[] | null> = ref(null);
    const products: Ref<Product[] | null> = ref(null);
    const productAllergiesMatch: Ref<ProductAllergyMatch[] | null> = ref(null);
    const productAllergiesMatchColumnsName: Ref<string[] | null> = ref(null);
    const severities: Ref<Severity[] | null> = ref(null);
    // erreurs call api
    const error = ref<Error | null>(null);

    // setters
    const setProduct = (value: Product | null) => (product.value = value);
    const setIngredients = (value: Ingredient[] | null) =>
      (ingredients.value = value);
    const setAllergens = (value: Ingredient[] | null) =>
      (allergens.value = value);
    const setTraces = (value: Ingredient[] | null) => (traces.value = value);
    const setProducts = (value: Product[] | null) => (products.value = value);
    const setProductAllergiesMatch = (value: ProductAllergyMatch[] | null) =>
      (productAllergiesMatch.value = value);
    const setProductAllergiesMatchColumnsName = (value: string[] | null) =>
      (productAllergiesMatchColumnsName.value = value);
    const setSeverities = (value: Severity[] | null) =>
      (severities.value = value);
    const setError = (value: Error | null) => (error.value = value);

    // reset store
    const resetStore = () => {
      setProduct(null);
      setIngredients(null);
      setAllergens(null);
      setTraces(null);
      setProducts(null);
      setProductAllergiesMatch(null);
      setProductAllergiesMatchColumnsName(null);
      setSeverities(null);
      setError(null);
    };

    const checkProduct = async (barcode: number) => {
      await apiCall(async () => {
        const { data } = await createProduct(barcode);
        const product_user_id = data.product_user_id;
        await getProduct(product_user_id);
      });
    };

    const getProduct = async (product_user_id: number) => {
      await apiCall(async () => {
        setProduct(null);
        const { data } = await fetchProduct(product_user_id);
        setProduct(data.product as Product);
        setIngredients(data.ingredients);
        setAllergens(data.allergens);
        setTraces(data.traces);
        setProductAllergiesMatch(data.allergies_match as ProductAllergyMatch[]);
        setProductAllergiesMatchColumnsName(data.columns_name as string[]);
        setSeverities(data.severities as Severity[]);
        await router.push("/products/" + product_user_id);
      });
    };

    const getProducts = async () => {
      await apiCall(async () => {
        const { data } = await fetchProducts();
        setProducts(data.products as Product[]);
      });
    };

    const deleteProduct = async (productId: number) => {
      await apiCall(async () => {
        await destroyProducts(productId);
        location.reload();
      });
    };

    return {
      error,
      product,
      ingredients,
      traces,
      allergens,
      products,
      productAllergiesMatch,
      productAllergiesMatchColumnsName,
      severities,
      checkProduct,
      getProduct,
      getProducts,
      setError,
      resetStore,
      deleteProduct,
    };
  },
  { persist: true }
);
