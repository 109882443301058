import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useGlobalStore } from "@/stores/useGlobalStore";
import { Friend } from "@/interfaces/Friend";
import { FriendRequest } from "@/interfaces/FriendRequest";
import {
  acceptFriendRequest,
  addFriend,
  fetchAllFriends,
  rejectFriendRequest,
} from "@/api/friends";

export const useFriendsStore = defineStore(
  "friends",
  () => {
    const { apiCall, setSuccessMessage } = useGlobalStore();
    // friends
    const friendsConfirmed: Ref<Friend[] | null> = ref(null);
    const friendRequestsFromMe: Ref<FriendRequest[] | null> = ref(null);
    const friendRequestsForMe: Ref<FriendRequest[] | null> = ref(null);
    // erreurs call api
    const error = ref<Error | null>(null);

    // setters
    const setFriendsConfirmed = (value: Friend[] | null) =>
      (friendsConfirmed.value = value);

    const setFriendRequestsForMe = (value: FriendRequest[] | null) =>
      (friendRequestsForMe.value = value);

    const setFriendRequestsFromMe = (value: FriendRequest[] | null) =>
      (friendRequestsFromMe.value = value);

    const setError = (value: Error | null) => (error.value = value);

    // reset store
    const resetStore = () => {
      setFriendRequestsFromMe(null);
      setFriendRequestsForMe(null);
      setFriendsConfirmed(null);
      setError(null);
    };

    const getAllFriendsList = async () => {
      await apiCall(async () => {
        const { data } = await fetchAllFriends();
        setFriendsConfirmed(data.friendsConfirmed as Friend[]);
        setFriendRequestsForMe(data.friendRequestsForMe as FriendRequest[]);
        setFriendRequestsFromMe(data.friendRequestsFromMe as FriendRequest[]);
      });
    };

    const addNewFriend = async (name: string) => {
      await apiCall(async () => {
        await addFriend(name);
        await getAllFriendsList();
        setSuccessMessage("Demande d'ami envoyée avec succès");
      });
    };

    const acceptRequest = async (friendship_id: number) => {
      await apiCall(async () => {
        await acceptFriendRequest(friendship_id);
        location.reload();
      });
    };

    const rejectRequest = async (friendship_id: number) => {
      await apiCall(async () => {
        await rejectFriendRequest(friendship_id);
        location.reload();
      });
    };

    return {
      error,
      friendsConfirmed,
      friendRequestsForMe,
      friendRequestsFromMe,
      getAllFriendsList,
      addNewFriend,
      acceptRequest,
      rejectRequest,
      setError,
      resetStore,
    };
  },
  { persist: true }
);
