import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/useAuthStore";

const onlyLoggedOut: string[] = [
  "/home",
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
];
const onlyLoggedIn: string[] = [
  "/profile",
  "/user-home",
  "/user/:id/allergies",
  "/add-allergies",
  "/user-allergies/create",
  "/allergies/:id/show",
  "/cross-allergies",
  "/barcode-scan",
  "/products/:id",
  "/pollens",
  "/food-differences",
  "/insect-sting",
  "/pets",
  "/medications",
  "/materials",
  "/eczema",
  "/foods",
  "/food-intolerances",
  "/infos",
  "/faq-alertgy",
  "/faq-products",
  "/faq-nutriscore",
  "/emergency",
  "/403",
  "/verify-email/:id/:hash",
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },

  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "Accueil",
      description: "Bienvenue sur notre application de gestion des allergies.",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: "Connexion",
      description: "Connectez-vous pour accéder à votre compte.",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
    meta: {
      title: "Inscription",
      description:
        "Créez un compte pour commencer à utiliser notre application.",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPasswordView.vue"),
    meta: {
      title: "Mot de passe oublié",
      description: "Réinitialisez votre mot de passe si vous l'avez oublié.",
    },
  },
  {
    path: "/reset-password/:token/:email",
    name: "reset-password",
    component: () => import("@/views/ResetPasswordView.vue"),
    meta: {
      title: "Réinitialisation du mot de passe",
      description:
        "Réinitialisez votre mot de passe avec le lien reçu par email.",
    },
  },
  {
    path: "/user-home",
    name: "user-home",
    component: () => import("@/views/UserHomeView.vue"),
    meta: {
      title: "Accueil utilisateur",
      description: "Accédez à votre tableau de bord utilisateur.",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileView.vue"),
    meta: {
      title: "Profil",
      description: "Consultez et modifiez votre profil.",
    },
  },
  {
    path: "/user/:id/allergies",
    name: "user-allergies",
    component: () => import("@/views/UserAllergiesView.vue"),
    meta: {
      title: "Allergies de l'utilisateur",
      description: "Consultez vos allergies ou celle de vos amis.",
    },
  },
  {
    path: "/add-allergies",
    name: "add-allergies",
    component: () => import("@/views/AddUserAllergiesView.vue"),
    meta: {
      title: "Ajouter des allergies",
      description: "Ajoutez de nouvelles allergies à votre profil.",
    },
  },
  {
    path: "/allergies/:id/show",
    name: "show-allergy-user",
    component: () => import("@/views/UserAllergyShowView.vue"),
    meta: {
      title: "Détails de l'allergie",
      description: "Affichez les détails d'une allergie spécifique.",
    },
  },
  {
    path: "/cross-allergies",
    name: "cross-allergies",
    component: () => import("@/views/CrossAllergyShowView.vue"),
    meta: {
      title: "Allergies croisées",
      description:
        "Consultez les allergies croisées entre les différentes allergies.",
    },
  },
  {
    path: "/user-allergies/create",
    name: "user-allergies-create",
    component: () => import("@/views/UserAllergiesCreateView.vue"),
    meta: {
      title: "Créer une allergie",
      description: "Ajoutez une nouvelle allergie.",
    },
  },
  {
    path: "/barcode-scan",
    name: "barcode-scan",
    component: () => import("@/views/BarcodeScanView.vue"),
    meta: {
      title: "Scanner de code-barres",
      description:
        "Scannez les codes-barres des produits pour vérifier les allergies.",
    },
  },
  {
    path: "/history-scan",
    name: "history-scan",
    component: () => import("@/views/ScanHistoryView.vue"),
    meta: {
      title: "Historique des scans",
      description: "Consultez l'historique de vos scans de produits.",
    },
  },
  {
    path: "/products/:id",
    name: "products-id",
    component: () => import("@/views/ProductView.vue"),
    meta: {
      title: "Détails du produit",
      description: "Affichez les détails d'un produit spécifique.",
    },
  },
  {
    path: "/pollens",
    name: "pollens",
    component: () => import("@/views/PollensView.vue"),
    meta: {
      title: "Informations sur les pollens",
      description:
        "Consultez les informations sur les niveaux de pollen actuels et pleins d'informations pratique.",
    },
  },
  {
    path: "/food-differences",
    name: "food-differences",
    component: () => import("@/views/FoodDifferencesView.vue"),
    meta: {
      title: "Différence entre les allergies et les intolérances alimentaire",
      description:
        "Consultez les informations sur les différences entre les allergies et intolérances alimentaire.",
    },
  },
  {
    path: "/insect-string",
    name: "insect-string",
    component: () => import("@/views/InsectSingView.vue"),
    meta: {
      title: "Informations sur les piqûres d'insectes",
      description: "Consultez les informations sur les piqûres d'insecte.",
    },
  },
  {
    path: "/pets",
    name: "pets",
    component: () => import("@/views/PetsView.vue"),
    meta: {
      title: "Informations sur les allergies aux animaux",
      description: "Consultez les informations sur les allergies aux animaux.",
    },
  },
  {
    path: "/medications",
    name: "medications",
    component: () => import("@/views/MedicationView.vue"),
    meta: {
      title: "Informations sur les allergies aux médicaments",
      description:
        "Consultez les informations sur les allergies aux médicaments.",
    },
  },
  {
    path: "/materials",
    name: "materials",
    component: () => import("@/views/MaterialsView.vue"),
    meta: {
      title: "Informations sur les allergies aux matériaux",
      description:
        "Consultez les informations sur les allergies aux matériaux.",
    },
  },
  {
    path: "/eczema",
    name: "eczema",
    component: () => import("@/views/EczemaView.vue"),
    meta: {
      title: "Informations sur l'eczéma",
      description: "Consultez les informations sur l'eczéma.",
    },
  },
  {
    path: "/foods",
    name: "foods",
    component: () => import("@/views/FoodsView.vue"),
    meta: {
      title: "Informations sur les allergies alimentaire",
      description: "Consultez les informations sur les allergies alimentaire.",
    },
  },
  {
    path: "/food-intolerances",
    name: "food-intolerances",
    component: () => import("@/views/FoodIntolerancesView.vue"),
    meta: {
      title: "Informations sur les intolérances alimentaire",
      description:
        "Consultez les informations sur les intolérances alimentaire.",
    },
  },
  {
    path: "/friendships",
    name: "friendships",
    component: () => import("@/views/FriendshipsView.vue"),
    meta: {
      title: "Amis",
      description: "Gérez vos amis et consultez leurs allergies.",
    },
  },
  {
    path: "/infos",
    name: "infos",
    component: () => import("@/views/InfosView.vue"),
    meta: {
      title: "Informations",
      description: "Consultez les informations générales sur les allergies.",
    },
  },
  {
    path: "/faq-alertgy",
    name: "faq-alertgy",
    component: () => import("@/views/FaqAlertgyView.vue"),
    meta: {
      title: "Faq - Alertgy",
      description: "Consultez la foire aux questions sur Alertgy.",
    },
  },
  {
    path: "/faq-products",
    name: "faq-products",
    component: () => import("@/views/FaqProductView.vue"),
    meta: {
      title: "Faq - Produit",
      description: "Consultez la foire aux questions sur les produits scannés.",
    },
  },
  {
    path: "/faq-nutriscore",
    name: "faq-nutriscore",
    component: () => import("@/views/FaqNutriscoreView.vue"),
    meta: {
      title: "Faq - Nutriscore",
      description: "Consultez la foire aux questions sur le nutriscore.",
    },
  },
  {
    path: "/emergency",
    name: "emergency",
    component: () => import("@/views/EmergencyView.vue"),
    meta: {
      title: "Numéros d'urgences",
      description: "Consultez les numéros d'urgence de tous les pays.",
    },
  },
  {
    path: "/allergies/:id/edit",
    name: "edit-allergy-user",
    component: () => import("@/views/UserAllergiesEditView.vue"),
    meta: {
      title: "Modification de l'allergie",
      description:
        "Affichez les détails d'une allergie spécifique afin de pouvoir la modifier.",
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404View.vue"),
    meta: {
      title: "Page non trouvée",
      description: "La page que vous recherchez n'a pas été trouvée.",
    },
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/403View.vue"),
    meta: {
      title: "Vérification de l'email",
      description:
        "Vérification de l'email avant de pouvoir accéder à l'application.",
    },
  },
  {
    path: "/verify-email/:id/:hash",
    name: "verify-email",
    component: () => import("@/views/VerifyEmailView.vue"),
    meta: {
      title: "Vérification de l'email - token",
      description:
        "Vérification de l'email par le token avant de pouvoir accéder à l'application.",
    },
  },
  {
    path: "/terms-of-use",
    name: "termsOfUse",
    component: () => import("@/views/TermsOfUseView.vue"),
    meta: {
      title: "Conditions Générales d'Utilisation",
      description: "Conditions Générales d'Utilisation",
    },
  },
  {
    path: "/gdpr",
    name: "gdpr",
    component: () => import("@/views/GdprView.vue"),
    meta: {
      title: "Règlement sur la Protection des Données",
      description: "Règlement sur la Protection des Données",
    },
  },
  {
    path: "/legal-notice",
    name: "legal-notice",
    component: () => import("@/views/LegaleNoticeView.vue"),
    meta: {
      title: "Mentions légales",
      description: "Mentions légales",
    },
  },
  {
    path: "/cookies-policy",
    name: "cookies-policy",
    component: () => import("@/views/CookiesPolicyView.vue"),
    meta: {
      title: "Politique de Cookies",
      description: "Politique de Cookies",
    },
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  updateMetaTags(to);

  if (onlyLoggedOut.indexOf(to.path) >= 0 && authStore.user) {
    next("/user-home");
  } else if (onlyLoggedIn.indexOf(to.path) >= 0 && !authStore.user) {
    next("/login");
  } else if (
    to.path !== "/403" &&
    to.name !== "verify-email" &&
    authStore.user &&
    !authStore.user.email_verified_at
  ) {
    next("/403");
  } else {
    next();
  }
});

function updateMetaTags(to: RouteLocationNormalized) {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithDescription = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.description);

  if (nearestWithTitle) {
    document.title = ("Alertgy - " + nearestWithTitle.meta.title) as string;
  }

  if (nearestWithDescription) {
    const descriptionTag = document.querySelector('meta[name="description"]');
    const description = nearestWithDescription.meta.description as string;
    if (descriptionTag) {
      descriptionTag.setAttribute("content", description);
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content = description;
      document.head.appendChild(metaTag);
    }
  }
}

export default router;
