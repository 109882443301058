import axios, { AxiosInstance } from "axios";
import { useAuthStore } from "@/stores/useAuthStore";

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();

  return "";
}

const setCsrfToken = (token: string) => {
  axios.defaults.headers.common["X-XSRF-TOKEN"] = token;
};

const getCsrfToken = async () => {
  await axios.get("/sanctum/csrf-cookie");
  setCsrfToken((getCookie("XSRF-TOKEN") as string) || "");
};

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Accept"] = "application/json";
setCsrfToken((getCookie("XSRF-TOKEN") as string) || "");

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 419) {
      await getCsrfToken();
      return axios.request(error.config);
    } else if (error.response?.status === 401) {
      const { removeAuthentificationData } = useAuthStore();
      await removeAuthentificationData();
    }

    return Promise.reject(error);
  }
);

export default axios as AxiosInstance;
