import axios from "./index";
import { User } from "@/interfaces/User";
import { Allergy } from "@/interfaces/Allergy";
export async function fetchUserAllergies(user_id: number) {
  return await axios.get("api/users/" + user_id + "/allergies");
}

export async function fetchAllergiesToAdd(user: User) {
  return await axios.get("api/users/" + user.id + "/allergies/add");
}

export async function fetchUserAllergy(user: User, id: number) {
  return await axios.get("api/users/" + user.id + "/user-allergies/" + id);
}

export async function fetchUserCrossAllergy(user: User) {
  return await axios.get("api/users/" + user.id + "/cross-allergies");
}

export async function fetchUserAllergiesInfosToAdd(
  user: User,
  categoriesWithAllergiesId: Record<number, number[]>
) {
  return await axios.post("api/users/" + user.id + "/allergies/create", {
    categoriesWithAllergiesId,
  });
}

export async function storeUserAllergies(
  user: User,
  allergies: {
    allergy: Allergy;
    type: number | null;
    condition: number;
    severity: number | null;
    symptoms: number[];
    is_sensitive_to_traces: boolean;
    is_sensitive_to_touch: boolean;
  }[]
) {
  return await axios.post("api/users/" + user.id + "/allergies/store", {
    allergies,
  });
}

export async function fetchUserHomeInfo() {
  return await axios.get("api/home-info");
}

export async function fetchPollens() {
  return await axios.get("api/pollens");
}

export async function destroyAllergy(user_allergy_id: number) {
  return await axios.delete("api/user-allergies/" + user_allergy_id);
}

export async function fetchUserAllergyInfosToEdit(user_allergy_id: number) {
  return await axios.get("api/user-allergies/" + user_allergy_id + "/edit");
}

export async function updateUserAllergies(
  allergy_user_id: number,
  severity_id: number,
  type_id: number | null,
  condition_id: number | null,
  symptom_ids: number[],
  is_sensitive_to_traces: boolean | null,
  is_sensitive_to_touch: boolean | null
) {
  return await axios.put("api/user-allergies/" + allergy_user_id + "/update", {
    severity_id,
    type_id,
    condition_id,
    symptom_ids,
    is_sensitive_to_traces,
    is_sensitive_to_touch,
  });
}
