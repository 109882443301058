import { createApp, Directive } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import "../src/assets/css/index.css";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { vueDebounce } from "vue-debounce";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App)
  .use(router)
  .use(pinia)
  .directive(
    "debounce",
    vueDebounce({ lock: true, listenTo: "input" }) as Directive
  )
  .mount("#app");
