import { defineStore, storeToRefs } from "pinia";
import { reactive, Ref, ref } from "vue";
import { useGlobalStore } from "@/stores/useGlobalStore";
import {
  fetchAllergiesToAdd,
  fetchUserAllergies,
  fetchUserAllergiesInfosToAdd,
  fetchUserAllergy,
  fetchUserCrossAllergy,
  storeUserAllergies,
  fetchUserHomeInfo,
  fetchPollens,
  destroyAllergy,
  fetchUserAllergyInfosToEdit,
  updateUserAllergies,
} from "@/api/allergies";
import { User } from "@/interfaces/User";
import { UserAllergyCategory } from "@/interfaces/UserAllergyCategory";
import { UserAllergy } from "@/interfaces/UserAllergy";
import { CrossAllergy } from "@/interfaces/CrossAllergy";
import { Allergy } from "@/interfaces/Allergy";
import router from "@/router";
import { Type } from "@/interfaces/Type";
import { Condition } from "@/interfaces/Condition";
import { Severity } from "@/interfaces/Severity";
import { SymptomCategory } from "@/interfaces/SymptomCategory";
import { CurrentPollen } from "@/interfaces/CurrentPollen";
import { Symptom } from "@/interfaces/Symptom";
import { Friend } from "@/interfaces/Friend";
import { UserAllergySimple } from "@/interfaces/UserAllergySimple";
import { useAuthStore } from "@/stores/useAuthStore";

export const useAllergiesStore = defineStore(
  "allergies",
  () => {
    const { apiCall } = useGlobalStore();
    // user allergies
    const userAllergyCategories: Ref<UserAllergyCategory | null> = ref(null);
    const totalUserAllergies: Ref<number | null> = ref(null);
    const friendId: Ref<number | null> = ref(null);
    const friendName: Ref<string | null> = ref(null);
    // allergies ajout index
    const allergyToAddCategories: Ref<UserAllergyCategory | null> = ref(null);
    // allergy utilisateur show
    const userAllergy: Ref<UserAllergy | null> = ref(null);
    // allergies croisées
    const crossAllergies: Ref<CrossAllergy[] | null> = ref(null);
    // Formulaire ajout + filtres
    const allergiesInfosToAdd: Ref<Allergy[] | null> = ref(null);
    const types: Ref<Type[] | null> = ref(null);
    const conditions: Ref<Condition[] | null> = ref(null);
    const severities: Ref<Severity[] | null> = ref(null);
    const symptomCategories: Ref<SymptomCategory[] | null> = ref(null);
    // Home page
    const currentPollens: Ref<CurrentPollen[] | null> = ref(null);
    const homeUserAllergies: Ref<UserAllergy[] | null> = ref(null);
    const homeCommonSymptoms: Ref<Symptom[] | null> = ref(null);
    const homeFriends: Ref<Friend[] | null> = ref(null);
    const homeLastAllergies: Ref<UserAllergySimple[] | null> = ref(null);
    // Pollens page
    const allPollens: Ref<CurrentPollen[] | null> = ref(null);
    // erreurs call api
    const error = ref<Error | null>(null);

    // setters
    const setUserAllergyCategories = (value: UserAllergyCategory | null) =>
      (userAllergyCategories.value = value);
    const setTotalUserAllergies = (value: number | null) =>
      (totalUserAllergies.value = value);
    const setFriendId = (value: number | null) => (friendId.value = value);
    const setFriendName = (value: string | null) => (friendName.value = value);
    const setAllergyToAddCategories = (value: UserAllergyCategory | null) =>
      (allergyToAddCategories.value = value);
    const setUserAllergy = (value: UserAllergy | null) =>
      (userAllergy.value = value);
    const setCrossAllergies = (value: CrossAllergy[] | null) =>
      (crossAllergies.value = value);
    const setAllergiesInfosToAdd = (value: Allergy[] | null) =>
      (allergiesInfosToAdd.value = value);
    const setTypes = (value: Type[] | null) => (types.value = value);
    const setConditions = (value: Condition[] | null) =>
      (conditions.value = value);
    const setSeverities = (value: Severity[] | null) =>
      (severities.value = value);
    const setSymptomCategories = (value: SymptomCategory[] | null) =>
      (symptomCategories.value = value);
    const setCurrentPollens = (value: CurrentPollen[] | null) =>
      (currentPollens.value = value);
    const setHomeUserAllergies = (value: UserAllergy[] | null) =>
      (homeUserAllergies.value = value);
    const setHomeCommonSymptoms = (value: Symptom[] | null) =>
      (homeCommonSymptoms.value = value);
    const setHomeFriends = (value: Friend[] | null) =>
      (homeFriends.value = value);
    const setHomeLastAllergies = (value: UserAllergySimple[] | null) =>
      (homeLastAllergies.value = value);
    const setAllPollens = (value: CurrentPollen[] | null) =>
      (allPollens.value = value);
    const setError = (value: Error | null) => (error.value = value);

    // reset store
    const resetStore = () => {
      setUserAllergyCategories(null);
      setTotalUserAllergies(null);
      setFriendId(null);
      setFriendName(null);
      setAllergyToAddCategories(null);
      setUserAllergy(null);
      setCrossAllergies(null);
      setAllergiesInfosToAdd(null);
      setTypes(null);
      setConditions(null);
      setSeverities(null);
      setSymptomCategories(null);
      setCurrentPollens(null);
      setHomeUserAllergies(null);
      setHomeCommonSymptoms(null);
      setHomeFriends(null);
      setHomeLastAllergies(null);
      setAllPollens(null);
      setError(null);
    };

    const getUserAllergies = async (user_id: number) => {
      await apiCall(async () => {
        const { data } = await fetchUserAllergies(user_id);
        setUserAllergyCategories(data.categories);
        setTotalUserAllergies(data.total_allergies);
        setFriendId(data.id);
        setFriendName(data.name);
      });
    };

    const getAllergiesToAdd = async (user: User) => {
      await apiCall(async () => {
        const { data } = await fetchAllergiesToAdd(user);
        setAllergyToAddCategories(data.categories);
      });
    };

    const getUserAllergy = async (user: User, userAllergyId: number) => {
      await apiCall(async () => {
        const { data } = await fetchUserAllergy(user, userAllergyId);
        setUserAllergy(data);
      });
    };

    const getUserCrossAllergy = async (user: User) => {
      await apiCall(async () => {
        const { data } = await fetchUserCrossAllergy(user);
        setCrossAllergies(data);
      });
    };

    const getUserAllergiesInfosToAdd = async (
      user: User,
      categoriesWithAllergiesId: Record<number, number[]>
    ) => {
      await apiCall(async () => {
        const { data } = await fetchUserAllergiesInfosToAdd(
          user,
          categoriesWithAllergiesId
        );
        setAllergiesInfosToAdd(data.allergies);
        setTypes(data.types);
        setConditions(data.conditions);
        setSeverities(data.severities);
        setSymptomCategories(data.symptomCategories);
        await router.push("/user-allergies/create");
      });
    };

    const postUserAllergiesToAdd = async (
      user: User,
      allergies: {
        allergy: Allergy;
        type: number | null;
        condition: number;
        severity: number | null;
        symptoms: number[];
        is_sensitive_to_traces: boolean;
        is_sensitive_to_touch: boolean;
      }[]
    ) => {
      await apiCall(async () => {
        await storeUserAllergies(user, allergies);
        allergiesInfosToAdd.value = null;
        await router.push("/user/" + user.id + "/allergies");
      });
    };

    const getUserHomeInfo = async () => {
      await apiCall(async () => {
        const { data } = await fetchUserHomeInfo();
        setCurrentPollens(data.pollens);
        setHomeUserAllergies(data.user_allergies);
        setHomeFriends(data.friends);
        setHomeCommonSymptoms(data.common_symptoms);
        setHomeLastAllergies(data.last_allergies);
      });
    };

    const getPollens = async () => {
      await apiCall(async () => {
        const { data } = await fetchPollens();
        setCurrentPollens(data.currentPollens);
        setAllPollens(data.allPollens);
      });
    };

    const sendAllergiesToAdd = async (
      allergy_id: number,
      category_id?: number
    ) => {
      if (category_id) {
        const selectedAllergiesByCategory = reactive<Record<number, number[]>>(
          {}
        );
        if (!selectedAllergiesByCategory[category_id]) {
          selectedAllergiesByCategory[category_id] = [];
        }
        selectedAllergiesByCategory[category_id].push(allergy_id);
        const { user } = storeToRefs(useAuthStore());
        if (user.value) {
          await getUserAllergiesInfosToAdd(
            user.value,
            selectedAllergiesByCategory
          );
        } else {
          console.error(
            "Tentative d'appel getUserAllergiesInfosToAdd sans utilisateur."
          );
        }
      }
    };

    const deleteAllergy = async (userAllergyId: number) => {
      await apiCall(async () => {
        await destroyAllergy(userAllergyId);
        const { user } = storeToRefs(useAuthStore());
        await router.replace("/user/" + user.value?.id + "/allergies");
      });
    };

    const getUserAllergiesInfosToEdit = async (userAllergyId: number) => {
      await apiCall(async () => {
        const { data } = await fetchUserAllergyInfosToEdit(userAllergyId);
        setUserAllergy(data.userAllergy);
        setTypes(data.types);
        setConditions(data.conditions);
        setSeverities(data.severities);
        setSymptomCategories(data.symptomCategories);
      });
    };

    const postUserAllergiesToUpdate = async (
      allergy_user_id: number,
      severity_id: number,
      type_id: number | null,
      condition_id: number | null,
      symptom_ids: number[],
      is_sensitive_to_traces: boolean | null,
      is_sensitive_to_touch: boolean | null
    ) => {
      await apiCall(async () => {
        await updateUserAllergies(
          allergy_user_id,
          severity_id,
          type_id,
          condition_id,
          symptom_ids,
          is_sensitive_to_traces,
          is_sensitive_to_touch
        );
        await router.back();
      });
    };

    return {
      error,
      userAllergyCategories,
      totalUserAllergies,
      friendId,
      friendName,
      allergyToAddCategories,
      userAllergy,
      crossAllergies,
      allergiesInfosToAdd,
      types,
      conditions,
      severities,
      symptomCategories,
      currentPollens,
      homeUserAllergies,
      homeCommonSymptoms,
      homeFriends,
      homeLastAllergies,
      allPollens,
      getUserAllergies,
      getAllergiesToAdd,
      getUserAllergy,
      getUserCrossAllergy,
      getUserAllergiesInfosToAdd,
      postUserAllergiesToAdd,
      setUserAllergyCategories,
      setTotalUserAllergies,
      setAllergyToAddCategories,
      setUserAllergy,
      setCrossAllergies,
      setAllergiesInfosToAdd,
      setTypes,
      setConditions,
      setSeverities,
      setSymptomCategories,
      setError,
      resetStore,
      getUserHomeInfo,
      sendAllergiesToAdd,
      getPollens,
      deleteAllergy,
      getUserAllergiesInfosToEdit,
      postUserAllergiesToUpdate,
    };
  },
  { persist: true }
);
