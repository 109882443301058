import axios from "./index";
export async function fetchProduct(product_user_id: number) {
  return await axios.get("api/products/" + product_user_id);
}

export async function createProduct(barcode: number) {
  return await axios.post("api/products/create", {
    barcode,
  });
}

export async function fetchProducts() {
  return await axios.get("api/products");
}

export async function destroyProducts(product_user_id: number) {
  return await axios.delete("api/products/" + product_user_id);
}
