import axios from "./index";
import { AxiosResponse } from "axios";
export const fetchAllFriends = () => {
  return axios.get("api/friendships/all");
};

export const addFriend = (name: string): Promise<AxiosResponse> => {
  return axios.post("api/friendships/add", { name });
};

export const acceptFriendRequest = (friendship: number) => {
  return axios.get("api/friendships/" + friendship + "/accept");
};

export const rejectFriendRequest = (friendship: number) => {
  return axios.get("api/friendships/" + friendship + "/reject");
};
