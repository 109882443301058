import { defineStore } from "pinia";
import { ref } from "vue";
import {
  User,
  UserLogin,
  UserRegister,
  UserResetPassword,
} from "@/interfaces/User";
import {
  destroyAccount,
  fetchUser,
  postForgotPassword,
  postLogin,
  postLogout,
  postRegister,
  postResetPassword,
  postSendVerificationEmail,
  postUpdatePassword,
  postUpdateProfile,
  postVerifyEmail,
} from "@/api/auth";
import router from "@/router";
import { useGlobalStore } from "@/stores/useGlobalStore";
import { useAllergiesStore } from "@/stores/useAllergiesStore";
import { useFriendsStore } from "@/stores/useFriendsStore";
import { useProductsStore } from "@/stores/useProductsStore";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const { apiCall, setSuccessMessage } = useGlobalStore();
    const user = ref<User | null>(null);

    const login = async (userCredentials: UserLogin) => {
      await apiCall(async () => {
        const { data } = await postLogin(userCredentials);
        setUser(data as User);
        await router.push("/user-home");
      });
    };

    const register = async (userCredentials: UserRegister) => {
      await apiCall(async () => {
        const { data } = await postRegister(userCredentials);
        setUser(data as User);
        await router.push("/user-home");
      });
    };

    const logout = async () => {
      await apiCall(async () => {
        await postLogout();
        await removeAuthentificationData();
      });
    };
    const removeAuthentificationData = async () => {
      const { resetStore: resetAllergiesStore } = useAllergiesStore();
      const { resetStore: resetFriendsStore } = useFriendsStore();
      const { resetStore: resetProductsStore } = useProductsStore();
      const { resetStore: resetGlobalStore } = useGlobalStore();
      setUser(null);
      resetAllergiesStore();
      resetFriendsStore();
      resetProductsStore();
      resetGlobalStore();
      await router.replace("/login");
    };

    const setUser = (newUser: User | null) => {
      user.value = newUser;
    };

    const getUser = async () => {
      await apiCall(async () => {
        const { data } = await fetchUser();
        user.value = data as User;
      });
    };
    const forgotPassword = async (email: string) => {
      await apiCall(async () => {
        const { data } = await postForgotPassword(email);
        setSuccessMessage(data.status);
      });
    };

    const resetPassword = async (userCredentials: UserResetPassword) => {
      await apiCall(async () => {
        const { data } = await postResetPassword(userCredentials);
        setSuccessMessage(data.status);
        await router.push("/login");
      });
    };

    const updateProfile = async (userCredentials: UserRegister) => {
      await apiCall(async () => {
        const oldUser = user.value;
        const { data } = await postUpdateProfile(userCredentials);
        setUser(data as User);
        setSuccessMessage("Profile modifié avec succès.");
        if (oldUser?.email !== data.email) {
          await postSendVerificationEmail(user.value);
          await router.push("/403");
        }
      });
    };

    const updatePassword = async (userCredentials: {
      current_password: string;
      password: string;
      password_confirmation: string;
    }) => {
      await apiCall(async () => {
        await postUpdatePassword(userCredentials);
        setSuccessMessage("Mot de passe modifié avec succès.");
      });
    };

    const deleteAccount = async (userCredentials: { password: string }) => {
      await apiCall(async () => {
        await destroyAccount(userCredentials);
        logout();
        setSuccessMessage("Compte supprimé avec succès.");
      });
    };

    const sendVerificationEmail = async () => {
      await apiCall(async () => {
        await postSendVerificationEmail(user.value);
        setSuccessMessage(
          "L'email a bien été envoyé à votre adresse email. N'hésitez pas à vérifier vos spams !"
        );
      });
    };

    const verifyEmail = async (id: string, hash: string) => {
      await apiCall(async () => {
        await postVerifyEmail(id, hash);
        setSuccessMessage("Votre email a été vérifié avec succès.");
        await getUser();
        await router.push("/user-home");
      });
    };

    return {
      user,
      getUser,
      login,
      register,
      logout,
      forgotPassword,
      resetPassword,
      removeAuthentificationData,
      updateProfile,
      updatePassword,
      deleteAccount,
      sendVerificationEmail,
      verifyEmail,
    };
  },
  { persist: true }
);
