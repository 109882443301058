import {
  User,
  UserLogin,
  UserRegister,
  UserResetPassword,
} from "@/interfaces/User";

import axios from "./index";

export async function postLogin(credentials: UserLogin) {
  return await axios.post("login", credentials);
}

export async function postRegister(credentials: UserLogin) {
  return await axios.post("register", credentials);
}

export async function fetchUser() {
  return await axios.get("api/user");
}

export async function postLogout() {
  return await axios.post("logout");
}

export async function postForgotPassword(email: string) {
  return await axios.post("forgot-password", { email: email, origin: "pwa" });
}

export async function postResetPassword(data: UserResetPassword) {
  return await axios.post("reset-password", { ...data, origin: "pwa" });
}

export async function postUpdateProfile(credentials: UserRegister) {
  return await axios.put("api/profile", credentials);
}

export async function postUpdatePassword(credentials: {
  current_password: string;
  password: string;
  password_confirmation: string;
}) {
  return await axios.put("api/password", credentials);
}

export async function destroyAccount(credentials: { password: string }) {
  return await axios.delete("api/profile", { data: credentials });
}

export async function postSendVerificationEmail(user: User | null) {
  return await axios.post("email/verification-notification", {
    user,
    origin: "pwa",
  });
}

export async function postVerifyEmail(id: string, hash: string) {
  return await axios.get(`/verify-email/${id}/${hash}`);
}
