import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { useOnline } from "@vueuse/core";
import { AxiosError } from "axios";
import { ErrorMessage } from "@/interfaces/ErrorMessage";
import router from "@/router";

export const useGlobalStore = defineStore("global", () => {
  const isOnline = useOnline();
  const isLoading = ref(false);
  const error = ref<Error | null>(null);
  const errorMessages: Ref<ErrorMessage[] | null> = ref(null);
  const successMessage: Ref<string | null> = ref(null);

  const setError = (value: Error | null) => (error.value = value);
  const setErrorMessage = (value: ErrorMessage[] | null) =>
    (errorMessages.value = value);
  const setSuccessMessage = (value: string | null) =>
    (successMessage.value = value);

  const resetStore = () => {
    setError(null);
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const apiCall = async (fn: () => Promise<void>) => {
    resetStore();
    if (!isOnline.value) {
      errorMessages.value = [
        { name: "general", error: "Pas de connexion Internet" },
      ];
      return;
    }
    isLoading.value = true;
    try {
      await fn();
    } catch (e: unknown) {
      if (e instanceof AxiosError && e.response) {
        if (e.response.status === 404) {
          await router.push("/404");
        } else if (e.response.status === 403) {
          await router.push("/403");
        } else if (e.response.data.errors) {
          const errors = e.response.data.errors as Record<string, string[]>;
          errorMessages.value = Object.entries(errors).flatMap(
            ([name, messages]) => messages.map((error) => ({ name, error }))
          );
        } else if (e.response.data.message) {
          errorMessages.value = [
            { name: "general", error: e.response.data.message },
          ];
        } else if (e.response.data.error) {
          errorMessages.value = [
            { name: "general", error: e.response.data.error },
          ];
        } else {
          errorMessages.value = [
            { name: "general", error: "Erreur inconnue lors de l'appel API" },
          ];
        }
      }
    } finally {
      isLoading.value = false;
    }
  };

  return {
    apiCall,
    isLoading,
    errorMessages,
    isOnline,
    resetStore,
    setSuccessMessage,
    successMessage,
  };
});
