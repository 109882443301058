<script setup lang="ts">
import { ref } from "vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import ExtraSmallTitle from "@/components/titles/ExtraSmallTitle.vue";
import ExtraSmallText from "@/components/texts/ExtraSmallText.vue";

const showReload = ref(false);

document.addEventListener("swUpdated", () => {
  showReload.value = true;
});

const reloadPage = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister().then(() => {
          window.location.reload();
        });
      }
    });
  }
};
</script>

<template>
  <div
    v-if="showReload"
    class="fixed top-4 right-4 left-4 md:left-auto bg-white dark:bg-black p-10 rounded shadow z-[300] text-black flex flex-col gap-4"
    role="alert"
  >
    <div class="flex flex-col gap-2">
      <ExtraSmallTitle class="text-black dark:text-white">
        Nouveau contenu disponible
      </ExtraSmallTitle>
      <ExtraSmallText class="text-black dark:text-white">
        Cliquez sur le bouton recharger pour mettre à jour.
      </ExtraSmallText>
    </div>
    <div class="flex flex-row gap-4">
      <ButtonSecondary @click="showReload = false"> Fermer </ButtonSecondary>
      <ButtonPrimary @click="reloadPage"> Recharger </ButtonPrimary>
    </div>
  </div>
</template>

<style scoped>
.bg-primary {
  background-color: #eebec1;
}
</style>
